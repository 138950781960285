import React from "react";
import { WhatsApp } from "@mui/icons-material";
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MessageIcon from '@mui/icons-material/Message';

import FormValidation from "./components/FormValidation";

import data from "./Data"


const FormSection = (props) => {
 
  return (
    <>
      <section id="forms" className="form" >
      <h2 className="text-center mt-5" style={{ fontFamily: 'Dancing Script', fontSize: '40px' }}>Confirmarea Prezenței</h2>
          
        
      {data.introData.map((item) => { 
        return(
          
          
           <div class="card text-center container" style={{ maxWidth: "55rem" }}>
          <div class="card-header" >
            <h4>Vom fi recunoscători dacă veți confirma prezența Dumneavoastră printr-un mesaj sau apel telefonic, indicând numărul persoanelor care vor fi prezente la eveniment la numerele de contact de mai jos. Vă mulțumim anticipat! </h4>
          
          </div>
       

       {/**  <FormValidation/>*/}  

          <div class="card-footer text-muted">
          Abia așteptăm să vă vedem! Dacă aveți orice altă întrebare,
vă rugăm să ne contactați sau scrieți-ne un mesaj:<br /><br />
          <b>{item.mama}  {item.tel}</b>   : <br /><a class=" m-2 btn_logo" href={item.phone}> <PhoneIphoneIcon /> Phone</a>
           <a className="m-2 btn_logo" href={item.viber}> <PhoneIcon />Viber</a>
           <a className="m-2  btn_logo" href={item.whatsapp}><WhatsApp />WhatsApp</a><br /><br />
            
           <b>{item.tata}  {item.tel1}</b>  : <br /><a class=" m-2 btn_logo" href={item.phone1}> <PhoneIphoneIcon /> Phone</a>
           <a className="m-2 btn_logo" href={item.viber1}> <PhoneIcon />Viber</a>
           <a className="m-2  btn_logo" href={item.whatsapp1}><WhatsApp />WhatsApp</a>
          <a className=" m-2  btn_logo" href={item.messenger}><MessageIcon />Messenger</a><br /><br />
          {/**   <a className=" m-2  btn_logo" href={item.messenger1}><MessageIcon />Messenger</a><br />*/}
          </div>
        </div> )
      })}
          
    
      </section>
    </>
  )


}

export default FormSection;