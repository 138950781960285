import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import {Helmet} from "react-helmet";
import Header from './components/Header';
import Home from './components/Home';
import FooterB from './components/Footer';
import './css/Body.css';
import data from './Data';


function App() {
  return (
    <>

{data.introData.map((item, index) => {
        return (

<>
 <Helmet>
        <meta charSet="utf-8" />
        <title>{item.familia}</title>
        <meta name="description" content={item.title} />
      </Helmet>
</>
          
        )
      })}


 {data.introData.map((item, index) => {
                    return (
                      <Header familia={item.familia} logo={item.logo} /> 
                    )
                })}

     
     
     <Home/>
    
     <FooterB/>

    
    </>

   
  );
}

export default App;
